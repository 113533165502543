body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: PilatCompressed-Book;
  src: url('../public/fonts/PilatCompressed-Book.woff2');
}

@font-face {
  font-family: PilatCompressed-Heavy;
  src: url('../public/fonts/PilatCompressed-Heavy.woff2');
}

@font-face {
  font-family: SpaceMono-Regular;
  src: url('../public/fonts/SpaceMono-Regular.woff2');
}

@font-face {
  font-family: SpaceMono-Italic;
  src: url('../public/fonts/SpaceMono-Italic.woff2');
}

@font-face {
  font-family: PilatWide-Heavy;
  src: url('../public/fonts/PilatWide-Heavy.woff2');
}

@font-face {
  font-family: Pilat-Heavy;
  src: url('../public/fonts/Pilat-Heavy.woff2');
}